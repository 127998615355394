import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5radar from '@amcharts/amcharts5/radar';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { SpiderChartDataDTO } from '../../../../../core/modals/chart.modal';

@Component({
  selector: 'app-spider-chart',
  standalone: true,
  imports: [],
  templateUrl: './spider-chart.component.html',
  styleUrls: ['./spider-chart.component.scss'],
})
export class SpiderChartComponent implements OnInit, OnDestroy {
  @Input() data: SpiderChartDataDTO[] = [];
  @Input() chartId:string = "radarChartDiv";
  private root!: am5.Root;

  ngOnInit() {
    this.createChart();
  }

  createChart() {
    this.root = am5.Root.new(this.chartId);
    this.root.setThemes([am5themes_Animated.new(this.root)]);

    let chart = this.root.container.children.push(
      am5radar.RadarChart.new(this.root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        radius: am5.percent(95),
      })
    );

    let cursor = chart.set(
      'cursor',
      am5radar.RadarCursor.new(this.root, {
        behavior: 'zoomX',
      })
    );

    cursor.lineY.set('visible', false);

    let xRenderer = am5radar.AxisRendererCircular.new(this.root, {});
    xRenderer.labels.template.setAll({
      radius: 10,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        maxDeviation: 0,
        categoryField: 'item',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(this.root, {}),
      })
    );

    let yRenderer = am5radar.AxisRendererRadial.new(this.root, {});

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: yRenderer,
      })
    );

    yRenderer.grid.template.setAll({
      stroke: am5.color(0x174c3c), // Dark Green
    });

    let series = chart.series.push(
      am5radar.RadarLineSeries.new(this.root, {
        name: 'Series',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        categoryXField: 'item',
        fill: am5.color(0x174c3c), // Dark Green
        tooltip: am5.Tooltip.new(this.root, {
          labelText: '{valueY}',
        }),
      })
    );

    series.strokes.template.setAll({
      strokeWidth: 2,
      stroke: am5.color(0xf9c25a), // Bright Yellow
    });

    series.fills.template.setAll({
      fill: am5.color(0x174c3c), // Dark Green
      fillOpacity: 0.2,
    });

    series.bullets.push(() => {
      return am5.Bullet.new(this.root, {
        sprite: am5.Circle.new(this.root, {
          radius: 5,
          fill: series.get('fill'),
        }),
      });
    });

    chart.set('cursor', am5radar.RadarCursor.new(this.root, {}));

    series.data.setAll(this.data);
    xAxis.data.setAll(this.data);

    series.appear(1000);
    chart.appear(1000, 100);
  }

  ngOnDestroy() {
    if (this.root) {
      this.root.dispose();
    }
  }
}
